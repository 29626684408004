import React, { useState, useEffect } from "react"
import useScript from "../service/useScript"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import { API_URL, DOCTORS_FILTER, NODE_ENV } from "../service/config"
import featherIcon from "../../static/assets/images/icons/Icon-feather-search.svg"
import API from '../api/doctors'
import Specialities from '../components/addon/specialityDetails'
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css"
import { navigate, graphql } from 'gatsby'
import BtnLink from "../components/addon/Link"

const AsyncTypeahead = withAsync(Typeahead)

const SearchSpeciality = ({ data: { allNodeTemplatePages: { edges } } }) => {
    const [excellence, setExcellence] = useState([])
    const [specialities, setSpecialities] = useState([]);
    const [clinics, setClinics] = useState([])
    const [letter, setLetter] = useState("")

    let [searchList, setSearchList] = useState([])
    let [loading, setLoading] = useState(false)
    let [contentLoading, setContentLoading] = useState(false)
    let [searchPlaceHolder, setSearchPlaceholder] = useState("Search By Speciality")
    const [searchArea, setSearchArea] = useState([])


    const metaTag = edges?.[0]?.node?.metatag || []

    const forExcellence = [];
    const forSpecialities = [];
    const forClinics = [];
    const pageData = edges && Array.isArray(edges) && edges.length > 0 && edges[0];

    const pageContent = typeof (pageData?.node?.relationships?.components) !== undefined && pageData?.node?.relationships?.components.length > 0 && pageData?.node?.relationships?.components[0];

    const sectionTitle = pageContent && pageContent?.field_title;

    const sectionComps = pageContent && typeof (pageContent?.relationships?.components) !== "undefined" && Array.isArray(pageContent?.relationships?.components) && pageContent?.relationships?.components;


    const searchByLetter = (activeLetter) => {
        setLetter(activeLetter)
        let searchLetter = activeLetter.toLowerCase()
        const excellenceData = excellence.map(el => {
            if (el.name.toLowerCase().includes(searchLetter)) {
                el._show = true
            } else {
                el._show = false
            }
            return el;
        });
        setExcellence(excellenceData)
        const specialitiesData = specialities.map(el => {
            if (el.name.toLowerCase().includes(searchLetter)) {
                el._show = true
            } else {
                el._show = false
            }
            return el;
        });
        setSpecialities(specialitiesData)
        const clinicsData = clinics.map(el => {
            if (el.name.toLowerCase().includes(searchLetter)) {
                el._show = true
            } else {
                el._show = false
            }
            return el;
        });
        setClinics(clinicsData)
    }
    useScript("/assets/js/specFilter.js")
    useEffect(() => {
        const Specialities = async () => {
            const data = await API.getSpecialities().catch(err => console.log(err));
            if (data) {
                data.forEach((el, index) => {
                    const json = {
                        ...el,
                        _show: true
                    };
                    switch (el.section) {
                        case "Speciality Clinic":
                            forClinics.push(json);
                            break;
                        case "Specialities":
                            forSpecialities.push(json);
                            break;
                        case "Center of Excellence":
                            forExcellence.push(json)
                            break;
                        default:
                            break;
                    }
                })
                forExcellence.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
                forSpecialities.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
                forClinics.sort(function(a, b){
                    if(a.name < b.name) { return -1; }
                    if(a.name > b.name) { return 1; }
                    return 0;
                })
                setExcellence(forExcellence);
                setSpecialities(forSpecialities);
                setClinics(forClinics);

                let searchbar = []
                data.forEach((el, index) => {
                    let obj = {
                        name: el?.name,
                        path: el?.path
                    }

                    searchbar.push(obj);

                })
                // setSearchList(searchbar);
                setSearchArea(searchbar)
                setContentLoading(true)
            }
        }
        Specialities();
    }, [])

    const clearSearch = () => {

        setExcellence(excellence.map(el => {
            el._show = true
            return el
        }
        ))

        setSpecialities(specialities.map(el => {
            el._show = true
            return el
        }
        ))

        setClinics(clinics.map(el => {
            el._show = true
            return el
        })
        )

        setLetter("");
    }

    const getSpecialtiesAndServicesData = (text) => {
        let query = `?contains=${text}`
        API.getSpecialtiesAndServices(query)
        .then(res => {
            let searchbar = []
            if(res?.length) {
                res.forEach((el, index) => {
                    let obj = {
                        id: el?.path,
                        path: el?.path,
                        name: el?.name,
                        searchValue: text
                    }
                    searchbar.push(obj);
                })
                setSearchList(searchbar);
            } else {
                setSearchList(searchbar);
            }
        }) 
        .catch(err => {
            console.log(err)
        })
    }

    const onSearchTextSelect = (data) => {
        if (data && data.length > 0) {
            const obj = data[0]
            if (obj.path) {
                navigate(obj.path)
            }
        }
    }

    const onSearchTextChange = (searchText) => {
        const text = searchText
        if (text) {
            getSpecialtiesAndServicesData(text)
        } else if (text.trim() === '') {
            clearData()
        }
    }
    
    const clearData = () => {
        setSearchList([])
    }

    // const handleInputChange = (input) => {
    //     const result = searchArea.filter(el => el?.name.toLowerCase().includes(input.toLowerCase()) && el)
    //     setSearchList(result);
    // }

    const defaultPatientServiceSrc = "https://via.placeholder.com/85x85"


    return (
        <>
            <Layout>
                <Meta
                    files={
                        {
                            js: [],
                            css: ['/assets/css/services.css',
                                '/assets/css/accessibility.css']
                        }
                    }
                    tags={metaTag}
                />
                <main className="innerpage pt-first-section">
                    <section className="search_box_services">
                        <div className="container">
                            <div className="search_heading">
                                <h1>Search Speciality</h1>
                                {/* <p>The Hinduja Clinic offers specialised clinics for patients with specific problems.</p> */}
                            </div>
                            <div className="input-group">
                                {/* <input type="text" className="form-control" placeholder="Search by Name, Specialities & Health …" onChange={handleChange} /> */}
                                <AsyncTypeahead
                                    delay={500}
                                    placeholder={searchPlaceHolder}
                                    isLoading={loading}
                                    searchText="Searching..."
                                    onBlur={() => setSearchPlaceholder("Search By Speciality")}
                                    onFocus={() => setSearchPlaceholder("Search By Speciality")}
                                    options={searchList}
                                    onChange={onSearchTextSelect}
                                    onSearch={onSearchTextChange}
                                    // onInputChange={handleInputChange}
                                    labelKey="name"
                                    id="search_speciality"
                                    filterBy={['name', 'id', 'path', 'searchValue']}
                                    // filterBy={(option, props) => {
                                    //     return option.name.toLowerCase().includes(props.text.toLowerCase());
                                    // }}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-search" type="button"> <img src={featherIcon} alt="" /> </button>
                                </div>
                            </div>
                            <ul className="listing">
                                {/* <li> <a href="/diseases-and-condition">Browse Treatments</a> </li> */}
                                {/* <li><a href="#">Browse Conditions - A to Z</a></li> */}
                            </ul>
                        </div>
                    </section>
                    <section className="section-py">
                        {contentLoading === true ? 
                        <div className="container">
                            <div className="specialty_list_box">
                                <Specialities title="Centres" data={excellence} />
                            </div>
                            <div className="specialty_list_box">
                                <Specialities title="Specialities" data={specialities} />
                            </div>
                            <div className="specialty_list_box">
                                <Specialities title="Speciality Clinics" data={clinics} />
                            </div>
                        </div>
                         : <p className='text-center'>Loading...</p>}
                    </section>
                    <section className="services_section common_services three_cols section-py section-bg service_card_main_wrap">
                        <div className="container">
                            <div className="heading-section text-center">
                                {/* <img src="assets/images/icons/icon_health_checkup.svg" alt="" /> */}
                                <h2 className="section-heading-2 my-3">{sectionTitle || ""}</h2>
                                {/* <p class="heading_text">A wide range of health check packages for your loved ones</p> */}
                                <div className="row justify-content-center">
                                    {
                                        sectionComps.map((el, index) => {
                                            const image = el?.relationships?.image?.uri?.url;
                                            const altText = el?.image?.alt
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className="service_card service_card_wrap">
                                                        {
                                                            image ?
                                                                <figure>
                                                                    <img src={image} alt={altText} className="service_card_icons_image" />
                                                                </figure>
                                                                :
                                                                <figure>
                                                                    <img
                                                                        src={defaultPatientServiceSrc}
                                                                        alt={altText}
                                                                    />
                                                                </figure>
                                                        }
                                                        <h3>{el?.title || ""}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: typeof (el?.description?.processed) !== "undefined" && typeof (el?.description?.processed) !== null ? el?.description?.processed : "" }}>
                                                        </div>
                                                        <div className="btn_wrap">
                                                            <BtnLink
                                                                data={el}
                                                                classes="btn btn-primary-solid"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            {/* <div class="checkup_slider slick-initialized slick-slider">
                                <div class="slick-list draggable" style={{ padding: "0px 50px" }}><div class="slick-track" style={{ opacity: "1", width: "1011px", transform: "translate3d(0px, 0px, 0px)" }}><div class="slick-slide slick-current slick-center" data-slick-index="0" aria-hidden="true" style={{ width: "337px" }}>
                                    <div>
                                        <div class="slide" style={{ width: "100%", display: "inline-block" }}>
                                            <div class="checkup_card">
                                                <h3 class="name">Comprehensive Plus</h3>
                                                <p class="price">Rs. 6,500</p>
                                                <a href="#" class="btn btn-primary-solid" tabindex="-1">Book Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="slick-slide" data-slick-index="1" aria-hidden="true" style={{ width: "337px" }}>
                                        <div>
                                            <div class="slide" style={{ width: "100%", display: "inline-block" }}>
                                                <div class="checkup_card">
                                                    <h3 class="name">Comprehensive Plus</h3>
                                                    <p class="price">Rs. 6,500</p>
                                                    <a href="#" class="btn btn-primary-solid" tabindex="-1">Book Now</a>
                                                </div>
                                            </div>
                                        </div></div><div class="slick-slide slick-center" data-slick-index="2" aria-hidden="true" style={{ width: "337px" }}>
                                        <div>
                                            <div class="slide" style={{ width: "100%", display: "inline-block" }}>
                                                <div class="checkup_card">
                                                    <h3 class="name">Comprehensive Plus</h3>
                                                    <p class="price">Rs. 6,500</p>
                                                    <a href="#" class="btn btn-primary-solid" tabindex="-1">Book Now</a>
                                                </div>
                                            </div>
                                        </div></div></div></div></div> */}
                            {/* <div class="btn_wrap text-center mt-4">
                                <a href="#" class="btn btn-primary">View all</a>
                            </div> */}
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
                query aboutSpecialityServicesHealthCheckup {
                    allNodeTemplatePages(
                        filter: {path: {alias: {regex: "/services-healthcheckup$/"}}}
                ) {
                    edges {
                    node {
                    id
          title
                path {
                    alias
                }
                metatag {
                    attributes {
                        content
                        href
                        name
                        property
                        rel
                    }
                }
                relationships {
                    components: field_component_type {
                    __typename
              ... on paragraph__title_and_description_components {
                    id
                field_title
                field_subtitle
                field_name
                relationships {
                    components: field_components {
                    __typename
                    ...ParagraphImageTitleDescLinkspeciality
                  }
                }
              }
            }
          }
        }
      }
    }
  }

                fragment ParagraphImageTitleDescLinkspeciality on paragraph__image_title_desc_link {
                    id
    title: field_title
                title1: field_title_1
                description: field_description {
                    processed
                }
                link: field_link {
                    uri
      title
    }
                image: field_image {
                    alt
                }
                relationships {
                    image: field_image {
                    id
                    uri {
                        value
                        url
                    }
      }
    }
  }`

export default SearchSpeciality